import React, { Component } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import FlexibleContentFeatured from "components/FlexibleContent/FlexibleContentFeatured"
import SidebarBlock from "components/shared/SidebarBlock"
import Article from "components/shared/Article"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import DateFormat from "components/shared/DateFormat"
import Text from "components/shared/Text"
library.add(fas)

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary};
`

class PostLayout extends Component {
    render() {
        // console.log(this.props.data)
        const { data } = this.props
        return (
            <Container>
                <Article>
                    <header>

                        <p className="breadcrumb">
                            <Link to="/">
                                Home
                            </Link> /&nbsp;
                            <Link to="/news/">
                                News
                            </Link> /&nbsp;
                            <span>
                                Article
                            </span>
                        </p>

                        <h1 dangerouslySetInnerHTML={{ __html: data.title }} />

                    </header>
                    <Row>
                        <Col lg="8">

                            {data.featuredImage &&
                                <figure className="hero-image">
                                    <GatsbyImage
                                        image={data.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.featuredImage.node.altText} />
                                    {data.featuredImage.node.caption &&
                                        <figcaption dangerouslySetInnerHTML={{ __html: data.featuredImage.node.caption }} />
                                    }
                                </figure>
                            }

                            <Row>
                                <Col md="3">
                                    <Container>
                                        <Row>
                                            <Col xs={6} md={12}>
                                            <p className="date mb-4"><DateFormat date={data.date} /></p>
                                            </Col>
                                            <Col xs={6} md={12}>
                                                <div className="share">
                                                    <Text bold className="mb-2 mr-4 d-inline-block d-md-block">SHARE</Text>
                                                    <FacebookShareButton className="share-button d-inline-block mr-4" url={data.link}><FontAwesomeIconStyled icon={faFacebookF} /></FacebookShareButton>
                                                    <TwitterShareButton className="share-button d-inline-block" url={data.link}><FontAwesomeIconStyled icon={faTwitter} /></TwitterShareButton>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col md="9">
                                    {/*loop through the content blocks*/}
                                    <FlexibleContentFeatured blocks={data.acfTwoColumnFlexibleContent.twoColumnBlocks} />
                                </Col>

                            </Row>
                        </Col>
                        <Col lg={{ size: 3, offset: 1 }} className="sidebar">
                            <aside>
                                {/*loop through the sidebar items and print them out*/}
                                {data.acfTwoColumnFlexibleContent.sidebar && data.acfTwoColumnFlexibleContent.sidebar.map((node, i) => {
                                    switch (node.acfSidebarBlocks.sidebarBlockType[0].__typename) {
                                        case "WpSidebarBlock_Acfsidebarblocks_SidebarBlockType_ImageLink":
                                            return(
                                                <Col xs={6} md={4} lg={12} className="pb-3 pb-md-0 px-1 px-lg-0" key={i}>
                                                    <SidebarBlock id={node.databaseId} margin={true} />
                                                </Col>
                                            )

                                        case "WpSidebarBlock_Acfsidebarblocks_SidebarBlockType_Rolex":
                                            return(
                                                <Col xs={6} md={4} lg={12} className="d-none d-lg-block pb-3 pb-md-0 px-1 px-lg-0" key={i}>
                                                    <SidebarBlock id={node.databaseId} margin={true} />
                                                </Col>
                                            )
                                    
                                        default:
                                            return "";
                                    }
                                })}
                            </aside>
                        </Col>
                    </Row>
                </Article>
            </Container>
        );
    }
}


export default PostLayout