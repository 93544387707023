import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import PostLayout from "components/PostLayout/PostLayout"

const PostTemplate = (props) =>  {
  const data = props.data.wpPost;
  var imageUrl = [];
	const title = data.seo.title || data.title
	const description = data.seo.metaDesc || data.excerpt

    //do we have an image for sharing
	if (data.featuredImage) {
        imageUrl = data.featuredImage.sourceUrl
	}
	
    return (
        <Layout data={data} title={title} description={description} image={imageUrl} url={data.link} path={data.uri}>
            <PostLayout data={data} />
        </Layout>
    )
}

export default PostTemplate

export const pageQuery = graphql`
query($id: String!) {
  wpPost(id: {eq: $id}) {
		link
		uri
		title
		excerpt
		date
		slug
		featuredImage {
			node {
				altText
				sourceUrl
				localFile {
					childImageSharp {
						gatsbyImageData(
							width: 1280
							quality: 90
							placeholder: NONE
							layout: CONSTRAINED
						)
					}
				}
			}
		}
		seo {
			title
			metaDesc
		}
		...postTemplateQuery
	}
}`